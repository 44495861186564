import React, { useState,useEffect } from 'react'

import slider1 from '../assets/img/Photos/1.jpeg'
import slider2 from '../assets/img/Photos/2.jpeg'
import slider3 from '../assets/img/Photos/3.jpeg'
import slider5 from '../assets/img/Photos/5.jpeg'
import slider6 from '../assets/img/Photos/6.jpeg'
import slider7 from '../assets/img/Photos/7.jpeg'
import slider8 from '../assets/img/Photos/8.jpeg'
import slider9 from '../assets/img/Photos/9.jpeg'
import slider10 from '../assets/img/Photos/10.jpeg'
import slider11 from '../assets/img/Photos/11.jpeg'
import slider12 from '../assets/img/Photos/12.jpeg'
import slider13 from '../assets/img/Photos/13.jpeg'
import slider14 from '../assets/img/Photos/14.jpeg'
import slider18 from "../assets/img/slider/slider3.jpg"
import img2 from "../assets/img/slider/img2.jpg"

import Slider from 'react-slick'





const Images = [
  {
    id: 1,
    
    src: slider1,
  },
  {
    id: 2,
   
    src: slider2
  },
  {
    id: 3,

    src: slider3
  },
  {
    id: 4,

    src: img2
  },
  {
    id: 5,

    src: slider5
  },
  {
    id: 6,

    src: slider6
  },
  {
    id: 7,

    src: slider7
  },{
    id: 8,
    
    src: slider8
  },
  {
    id: 9,
   
    src: slider9
  },
  {
    id: 10,

    src: slider10
  },
  {
    id: 11,

    src: slider11
  },
  {
    id: 12,

    src: slider12
  },
  {
    id: 13,

    src: slider13
  },
  {
    id: 14,

    src: slider14
  },

  {
    id: 18,

    src: slider18
  },

]





export default function Galerie() {

  const [nav1, setNav1] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0)
  const [slider, setslider] = useState(null);
  useEffect(() => {
    setNav1(slider);
  }, [slider]);

  const settings = {
    dots: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1000,
    lazyLoad: true,
    asNavFor: ".slider-nav",
    focusOnSelect: true,
    nextArrow: (
      <div>
        <div className="next-slick-arrow page-item" style={{marginInline:'20px'}}>
            <span className="page-link rounded-0"  aria-label="Next">
            <span aria-hidden="true" ><i className="bi bi-arrow-right"></i></span>
            </span>
        
           
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="next-slick-arrow  page-item" style={{marginInline:'20px'}}>
            <span className="page-link rounded-0"  aria-label="Previous">
            <span aria-hidden="true" ><i className="bi bi-arrow-left"></i></span>
            </span>
        
           
        </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
         slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
         slidesToShow: 1,
        }
       }
    ]
  };

 

  return (
    <div className='container my-5 py-5'>


      <div className="container">
        <Slider {...settings}
            asNavFor={nav1}
            ref={(slider) => setslider(slider)}
        >
          {Images.map((item) => (
            <div  key={item.id}>
              <div className="img-body">
                <img src={item.src} alt="slider" />
              </div>
            </div>
          ))}
        </Slider>

    </div> 
    </div> 
  
  )
}
