import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axiosInstance from '../axiosInstance'
import standard from '../assets/img/room/standard.jpg'
import luxe from '../assets/img/room/luxe.jpg'
import grandluxe from '../assets/img/room/grandluxe.jpg'
import usePagination from './Pagination/usePagination'
import Pagination from './Pagination/Pagination'

export default function ReservationChambres ({ onClick }) {
  const [Chambresliste, setChambresliste] = React.useState([])

  const itemsPerPage = 3

  useEffect(() => {
    axiosInstance
      .get('rest/room-list')
      .then(response => {
        const data = response.data

        setChambresliste(data)
      })

      .catch(error => {
        console.log(error)
      })
  }, [])




  const {
    next: next1,
    prev: prev1,
    jump: jump1,
    currentData: currentData1,
    currentPage: currentPage1,
    maxPage: maxPage1,
    dataLength: dataLength1,
    beginItem: beginItem1,
    endItem: endItem1
  } = usePagination(Chambresliste, itemsPerPage);


  return (
    <div>
      
        <div
          className='row room-item m-0 mb-4 wow fadeInUp'
          data-wow-delay='0.1s'
          style={{
            visibility: 'visible',
            animationDelay: '0.1s',
            animationName: 'fadeInUp'
          }}
        >
         

        
            {currentData1?.map(room => (
              <div className='row mb-5'>
                <div
                  className='col-md-5 col-lg-12 col-xl-5 p-0'
                  style={{ minHeight: '250px' }}
                >
                  <div className=' h-100'>
                    <img
                      className=' w-100 h-100'
                      src={standard}
                      alt=''
                      style={{ objectFit: 'cover' }}
                    />
                  </div>
                </div>
                <div className='col-md-7 col-lg-12 col-xl-7 h-100 px-0'>
                  <div className='p-4'>
                    <div className='d-flex align-items-center justify-content-between mb-3'>
                      <small
                        className='bg-primary rounded py-1 px-3'
                        style={{ style: '#000000' }}
                      >
                        {room.prixNuitee} FCFA
                      </small>
                      <div className='ps-2'>
                        <small className='fa fa-star text-primary'></small>
                        <small className='fa fa-star text-primary'></small>
                      </div>
                    </div>
                    <h5 className='mb-3'>{room.Intitule}</h5>
                    <div className='d-flex mb-3'>
                      <small className='border-end me-3 pe-3'>
                        <i className='fa fa-bed text-primary me-2'></i>2
                        Personnes
                      </small>
                      <small>
                        <i className='fa fa-wifi text-primary me-2'></i>Wifi
                      </small>
                    </div>
                    <p className='text-body mb-0'>
                    Nous vous proposons les chambres Standard, Luxe, et Grand luxe confortables et spacieuses toutes connectées au réseau internet haut débit.
                    </p>
                  </div>
                  <div className='d-flex justify-content-between border-top mt-auto p-4'>
                    <div
                      onClick={() => onClick(room.code)}
                      className='btn btn-sm btn-dark rounded py-2 px-4'
                    >
                      voir les détails
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div
              className='row wow fadeInUp'
              data-wow-delay='0.1s'
              style={{
                visibility: 'visible',
                animationDelay: '0.1s',
                animationName: 'fadeInUp'
              }}
            >
              <div className='col-12'>
              {Chambresliste?.length > itemsPerPage && (
            <Pagination
              next={next1}
              prev={prev1}
              currentData={currentData1}
              dataLength={dataLength1}
              itemsPerPage={itemsPerPage}
              beginItem={beginItem1}
              endItem={endItem1}
            />
          )}
              </div>
            </div>
          
         
         
          
        </div>
    
    </div>
  )
}